import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Testimonials from "../components/Testimonials";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const EoliennesPageTemplate = ({
  image,
  title,
  heading,
  content,
  contentComponent,
  testimonials,
}) => {
  const PageContent = contentComponent || Content
  return (
    <div className="content">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          alignItems: 'flex-start',
        }}
      >
        <h2
          className="has-text-weight-bold is-size-1"
          style={{
            backgroundColor: '#e83327ff',
            color: 'white',
            padding: '1rem',
            marginTop: '2rem',
          }}
        >
          {title}
        </h2>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <h3 className="has-text-weight-semibold is-size-2">
                    {heading}
                  </h3>
                  <PageContent content={content} />
                  <br />
                  <div className="has-text-centered">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="red-btn"
                      title="NON aux centrales éoliennes dans les Monts d'Arrée"
                      href="https://www.change.org/p/non-aux-centrales-%C3%A9oliennes-dans-les-monts-d-arr%C3%A9e"
                    >
                      Signer la pétition
                    </a>
                  </div>
                  <h3 className="has-text-weight-semibold is-size-2">
                    Témoignages de riverains et d'experts
                  </h3>
                  <p>
                    <Testimonials testimonials={testimonials} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

EoliennesPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  testimonials: PropTypes.array,
}

const EoliennesPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout>
      <EoliennesPageTemplate
        image={page.frontmatter.image}
        title={page.frontmatter.title}
        heading={page.frontmatter.heading}
        content={page.html}
        contentComponent={HTMLContent}
        testimonials={page.frontmatter.testimonials}
      />
    </Layout>
  )
}

EoliennesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default EoliennesPage

export const eoliennesPageQuery = graphql`
  query EoliennesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        testimonials {
          title
          video
          author
          quote
        }
      }
    }
  }
`
